import _ from 'lodash';

const calculateCostLumpsumByWeight = async ({
  previousLotList,
  currentLot,
  warehouseProfile,
  api,
  isFrontend,
}) => {
  const place = warehouseProfile?.place;
  /**
   * currentLot and currentLotMaterial can be null
   * when it called from cost estimation from beginer log
   * it will automatically be non-working in process
   * and the material will be the material prodcut
   * กรณีไม่ส่ง currentLot มา แสดงว่ามันมาจากหน้าต้นทุนจากล็อตเริ่มต้น
   * จะเป็นการไม่ Specify Current lot จะถถือว่าวัตถุดิบไม่ใช่ WIP และแสดงผลจากทุกอัน
   */
  const currentLotMaterial = currentLot?.material;
  const filterLotOnPlace = _.filter(
    previousLotList,
    (each) => each?.place?._id === place?._id,
  );
  const allowCategory = place?.allow_categories;

  // หาวัตถุดิบที่อนุญาคิให้อยู่ในคลังนี้
  let filterAllowanceMaterial;
  // กรณีเป็น Work in process เอามาเฉพาะที่สืบอดมาได้เท่านั้น
  if (currentLotMaterial?.is_workin_process) {
    const inputMaterialList = _.map(
      currentLotMaterial?.input_materials,
      (each) => each?.material,
    );

    filterAllowanceMaterial = _.filter(
      filterLotOnPlace,
      (each) =>
        _.includes(allowCategory, each?.material?.material_type) &&
        _.includes(inputMaterialList, each?.material?._id),
    );
    // กรณีไม่เป็น WIP เอามาทุกตัว
  } else {
    filterAllowanceMaterial = _.filter(filterLotOnPlace, (each) =>
      _.includes(allowCategory, each?.material?.material_type),
    );
  }

  const totalCostList = _.map(
    filterAllowanceMaterial,
    (each) => (each?.quantity || 0) * (each?.material?.unit_labor_cost || 0),
  );
  const totalCost = _.sum(totalCostList);

  const costList = _.map(filterAllowanceMaterial, (each) => ({
    ...each,
    labor_cost: (each?.quantity || 0) * (each?.material?.unit_labor_cost || 0),
  }));

  return { cost: totalCost, costList };
};

export default calculateCostLumpsumByWeight;
