import { CAPITAL_COST_ESTIMATION_METHOD } from '@iarcpsu/emanufac-constant';
import calculateCCDvideByLotWeight from './capitalCost/calculateCCDivideByLotWeight';
import calculateCCTDABC from './capitalCost/calculateCCTDABC';

const calculateCapitalCost = async ({
  warehouseProfile,
  previousLotList,
  currentLot,
  api,
  isFrontend,
  information,
  mongooseModel = {},
}) => {
  let cost = 0;
  let costList = [];
  let tempData = { cost: 0, costList: [] };
  switch (warehouseProfile?.capital_cost?.method) {
    case CAPITAL_COST_ESTIMATION_METHOD.DIVIDE_BY_LOT_WEIGHT.status_code:
      tempData = await calculateCCDvideByLotWeight({
        previousLotList,
        currentLot,
        warehouseProfile,
        api,
        isFrontend,
        information,
        ...mongooseModel,
      });
      cost = tempData.cost;
      costList = tempData.costList;
      break;
    case CAPITAL_COST_ESTIMATION_METHOD.TDABC.status_code:
      tempData = await calculateCCTDABC({
        previousLotList,
        currentLot,
        warehouseProfile,
        api,
        isFrontend,
        information,
        ...mongooseModel,
      });
      cost = tempData.cost;
      costList = tempData.costList;
      break;
    default:
      break;
  }
  return { cost, costList };
};

export default calculateCapitalCost;
