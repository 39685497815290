/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import { COST_ESTIMATION_ELEMENT } from '@iarcpsu/emanufac-constant';
import calculateCapitalCost from './type/calculateCaptitalCost';
import calculateLaborCost from './type/calculateLaborCost';
import calculateMaterialCost from './type/calculateMaterialCost';

const calculateCostOnProfile = async ({
  previousLotList,
  profile,
  currentLot,
  api,
  isFrontend,
  information,
  mongooseModel = {
    PlaceLaborCost: {},
    PayrollAdjustment: {},
    FactoryCapitalCost: {},
    MaterialStockLot: {},
    ObjectId: () => {},
  },
}) => {
  try {
    const result = [];
    for await (const warehouseProfile of profile.warehouses) {
      let cost = 0;
      let costList = [];

      switch (warehouseProfile.cost_estimation_element) {
        case COST_ESTIMATION_ELEMENT.MATERIAL.status_code:
          ({ cost, costList } = await calculateMaterialCost({
            warehouseProfile,
            previousLotList,
            currentLot,
            api,
            isFrontend,
            information,
            mongooseModel,
          }));
          break;

        case COST_ESTIMATION_ELEMENT.LABOR.status_code:
          ({ cost, costList } = await calculateLaborCost({
            warehouseProfile,
            previousLotList,
            currentLot,
            api,
            isFrontend,
            information,
            mongooseModel,
          }));
          break;

        case COST_ESTIMATION_ELEMENT.CAPITAL_COST.status_code:
          ({ cost, costList } = await calculateCapitalCost({
            warehouseProfile,
            previousLotList,
            currentLot,
            api,
            isFrontend,
            information,
            mongooseModel,
          }));
          break;

        default:
          break;
      }

      const tempResult = {
        // ...warehouseProfile,
        name: warehouseProfile?.name,
        _id: warehouseProfile?._id,
        cost,
        costList,
      };
      result.push(tempResult);
    }
    const totalCost = _.sumBy(result, 'cost');
    return { cost: totalCost, result };
  } catch (error) {
    console.error('Calculate Cost Error', error.message);
    return { cost: 0, result: [], error: error.message };
  }
};

export default calculateCostOnProfile;
