/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  BackButton,
  CostEstimationOverallContainer,
  Error,
  Loading,
  LoadingLinear,
  MaterialStockLotTimeline,
  MultiUnitProdOrMatBox,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  calculateCostOnProfile,
  findMaterialOriginalCost,
} from '@iarcpsu/emanufac-utils/functions';
import { Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { api, config } from '@/configs';
import * as actions from '@/redux/actions';
import { currencyFormatter } from '@/utils/functions';

/**
 * @function CostDriverDisplay
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function MaterialCostDriverDisplay({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const costEstimationProfile = useSelector(
    (state) => state.costEstimationProfile,
  );
  const information = useSelector((state) => state.information);
  const params = useParams();
  const [previousLotList, setPreviousLotList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [overallResults, setOverallResults] = useState([]);
  const originalCostList = findMaterialOriginalCost({
    useCostFromPrevious: true,
    allLotList: previousLotList,
    allowanceMaterial: [materialStockLot],
  });

  const originalCostReduce = _.reduce(
    originalCostList,
    (result, value) => {
      _.map(value?.original_costs, (eachLot) => {
        result.push(eachLot);
      });
      return result;
    },
    [],
  );

  // Test
  const queryDataFromServer = async () => {
    try {
      dispatch(actions.materialStockLotGet(params.id));
      dispatch(
        actions.costEstimationProfileAll({
          page: 1,
          size: config.maxFetchSize,
          notHidden: process.env.NODE_ENV === 'development' ? '' : true,
        }),
      );
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  const getPreviousLots = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/material-stock-lot/with-previous-lot/${params.id}`,
      );
      const tempPreviousLot = data?.rows;
      const uniqueLots = _.uniqBy(tempPreviousLot, '_id');
      const tempOrderedLot = _.orderBy(uniqueLots, 'createdAt');
      setPreviousLotList(tempOrderedLot);
    } catch (error) {
      console.error('Cannot Get Previous Lot', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCostOnProfile = async () => {
    try {
      const orderProfile = _.orderBy(
        costEstimationProfile?.rows,
        ['createdAt'],
        ['asc'],
      );
      const tempTotalResult = [];
      for await (const profile of orderProfile) {
        const tempResult = await calculateCostOnProfile({
          api,
          currentLot: materialStockLot,
          information,
          isFrontend: true,
          previousLotList,
          profile,
        });
        const tempPayload = {
          name: profile?.name,
          ...tempResult,
        };

        tempTotalResult.push(tempPayload);
      }
      setOverallResults(tempTotalResult);
      console.log('Total Result', tempTotalResult);
    } catch (error) {
      console.error('Get Cost On Profile error', error.message);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    getPreviousLots();
    return () => {};
  }, [params]);

  useEffect(() => {
    if (costEstimationProfile?.rows && !_.isEmpty(previousLotList)) {
      console.log('Ready to calculate cost of profile');
      getCostOnProfile();
    }

    return () => {};
  }, [previousLotList, costEstimationProfile]);

  const renderTitle = () => (
    <div className="flex justify-between items-center">
      <ViewTitle title={title} subtitle={subtitle} />
      <div className="flex gap-1">
        {_.map(originalCostReduce, (lot, index) => (
          <Link
            key={index}
            to={`/ims/cost-estimation/from-beginning/detail/${lot?.material_stock_lot}`}
          >
            <Button variant="contained" color="info">
              ต้นทุนของ {lot?.material?.name} เริ่มต้น
            </Button>
          </Link>
        ))}
      </div>
    </div>
  );

  if (materialStockLot.isLoading) {
    return <Loading />;
  }

  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <div>
            {isLoading ? (
              <LoadingLinear />
            ) : (
              <div>
                <div className="flex flex-wrap ">
                  <div className="w-full md:w-1/2 lg:w-1/3 pr-2">
                    <Card>
                      <CardContent>
                        <MaterialStockLotTimeline
                          previousLots={previousLotList}
                        />
                      </CardContent>
                    </Card>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-2/3">
                    <div className="mb-2">
                      <Card>
                        <CardContent>
                          <h3 className="font-semibold">วัตถุดิบปัจจุบัน</h3>
                          <div className="my-2 text-xl">
                            {materialStockLot?.material?.type_code}{' '}
                            {materialStockLot.material?.name}
                          </div>
                          <hr className="my-2" />
                          <div className="font-semibold my-1">
                            ประมาณการต้นทุน
                          </div>
                          <div className="flex justify-between flex-wrap my-2">
                            <div className="w-full md:w-1/2 lg:w-1/4">
                              <div>ปริมาณทั้งหมด</div>
                              <MultiUnitProdOrMatBox
                                foundMaterial={materialStockLot?.material}
                                quantity={materialStockLot?.quantity}
                              />
                            </div>
                            <div className="w-full md:w-1/2 lg:w-1/4">
                              <div>คงเหลือ</div>
                              <MultiUnitProdOrMatBox
                                foundMaterial={materialStockLot?.material}
                                quantity={materialStockLot?.amount}
                              />
                            </div>
                          </div>
                          {_.map(overallResults, (each, index) => (
                            <div
                              className="flex justify-between items-center"
                              key={index}
                            >
                              <div>{each?.name}</div>
                              <div className="text-lg">
                                {currencyFormatter.format(each?.cost)}
                                {'  '}
                                <span className="text-base">บาท</span>
                              </div>
                            </div>
                          ))}
                        </CardContent>{' '}
                      </Card>
                    </div>
                    <CostEstimationOverallContainer
                      costEstimationProfile={costEstimationProfile}
                      information={information}
                      currentLot={materialStockLot}
                      previousLotList={previousLotList}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

MaterialCostDriverDisplay.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialCostDriverDisplay.defaultProps = {
  title: '',
  subtitle: '',
};
