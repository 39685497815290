import _ from 'lodash';
import filterOnlyChildMaterial from '../../../cost-estimation/filterOnlyChildMaterial';
import filterAllowanceMaterial from '../../../cost-estimation/filterAllowanceMaterial';
import getFactoryCapitalCostInDay from '../../../cost-estimation/getFactoryCapitalCostInDay';
import { config } from '../../../../config';

const calculateCostPerMaerial = (row, costPerMinute) => {
  const minuteUse =
    (row?.material?.average_unit_time || 0) * (row?.quantity || 0);

  const costPerOneMan = costPerMinute * minuteUse;
  return costPerOneMan;
};

const calculateArtifactTDABC = async ({
  warehouseProfile,
  previousLotList,
  currentLot: inputCurrentLot,
  api,
  isFrontend,
  information,
  FactoryCapitalCost,
}) => {
  try {
    const place = warehouseProfile?.place;
    const allowMaterial = _.filter(
      previousLotList,
      (each) => each?.place?._id === place?._id,
    );
    const enableElement = warehouseProfile?.capital_cost?.element;
    const allowanceMaterial = filterAllowanceMaterial(previousLotList, place);
    const currentLot = inputCurrentLot || allowanceMaterial[0];
    const currentDate = currentLot?.receipt_date;

    const allowChildMaterial = filterOnlyChildMaterial({
      allowCategory: place?.allow_categories,
      currentLotMaterial: currentLot?.material,
      filterLotOnPlace: allowMaterial,
    });

    const factoryCapitalCost = await getFactoryCapitalCostInDay({
      currentDate,
      isFrontend,
      axios: api,
      maxFetchSize: config.maxFetchSize,
      information,
      enableElement,
      FactoryCapitalCost,
    });
    const hourPerDay = information?.setting?.pams?.cc_working_hour_per_day;
    const minutePerDay = hourPerDay * 60;
    const costPerMinute = factoryCapitalCost / minutePerDay;

    const newCostList = _.map(allowChildMaterial, (row) => ({
      ...row,
      used_time: (row?.material?.average_unit_time || 0) * (row?.quantity || 0),
      cost: calculateCostPerMaerial(row, costPerMinute),
    }));

    const totalCost = _.sumBy(newCostList, 'cost');

    return {
      costList: newCostList,
      cost: totalCost,
    };
  } catch (error) {
    console.error(
      'Cannot calculate artificial TDABC Capital Cost',
      error.message,
    );
    return {
      cost: 0,
      costList: [],
    };
  }
};

export default calculateArtifactTDABC;
