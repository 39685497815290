/* eslint-disable no-restricted-syntax */
/* eslint-disable implicit-arrow-linebreak */
import _ from 'lodash';
import dayjs from 'dayjs';
import findParentAndChildLot from './findParentAndChildLot';
import previousLotReciptDateSafe from './previousLotReciptDateSafe';
import findWorkType from '../ims/findWorkType';
import getEmployeePayrollUnitCostOnTime from './getEmployeePayrollUnitCostOnTimeWithAPI';
import getEmployeePayrollUnitCostOnTimeBackend from './getEmployeePayrollUnitCostOnTimeBackend';

const findLotWithCostRealTDABC = async ({
  previousLotList,
  information,
  allCostProcedure = false,
  includeAnotherEmployee = true,
  api,
  isFrontend = true,
  selectedPlace,
  PayrollAdjustment,
  ObjectId,
}) => {
  const pamsSetting = information?.setting?.pams;
  const parentWithChildLots = findParentAndChildLot(previousLotList);

  let count = 0;
  const employeeUsageList = [];

  for await (const eachParentWithChild of parentWithChildLots) {
    const previousWithChild = [
      eachParentWithChild,
      ...(eachParentWithChild?.child_lot || []),
    ];
    const orderedLot = _.orderBy(
      previousLotReciptDateSafe(previousWithChild),
      'receipt_date',
    );
    const groupByDatedLots = _.groupBy(orderedLot, 'receipt_date');

    //  console.log('Group By Dated Lot', groupByDatedLots);

    const timeKey = _.keys(groupByDatedLots);
    const pairTimeKey = _.zip(timeKey, timeKey.slice(1));
    const filterPairTime = _.filter(
      pairTimeKey,
      (eachPair) => eachPair?.[1] !== undefined,
    );
    let pairIndex = 0;
    for await (const eachPair of filterPairTime) {
      const timeUsage = dayjs(eachPair?.[1]).diff(
        dayjs(eachPair?.[0]),
        'minute',
      );

      const startLots = groupByDatedLots?.[eachPair?.[0]];
      const endLots = groupByDatedLots?.[eachPair?.[1]];

      const representStartLot = _.first(startLots);
      const representStartLotMaterialName = _.map(
        startLots,
        (each) => each?.material?.name,
      );
      const representEndLot = _.last(endLots);
      const representEndLotMaterialName = _.map(
        endLots,
        (each) => each?.material?.name,
      );

      // สมมติว่าเป็นกลุ่มของ Employee ก็ค่อยใส่ตัวคูณไป
      const representEmployee = representEndLot?.employee;
      let unitCost;
      if (isFrontend) {
        unitCost = await getEmployeePayrollUnitCostOnTime({
          representEmployee,
          timePair: eachPair,
          information,
          api,
        });
      } else {
        unitCost = await getEmployeePayrollUnitCostOnTimeBackend({
          representEmployee,
          timePair: eachPair,
          information,
          PayrollAdjustment,
          ObjectId,
        });
      }

      const workType = findWorkType({ representStartLot, representEndLot });
      if (
        pamsSetting?.wbm?.costed_procedure?.[workType] === true ||
        allCostProcedure
      ) {
        let placeName;
        let place = [];

        if (
          _.isEqual(representStartLot?.place?._id, representEndLot?.place?._id)
        ) {
          placeName = representStartLot?.place?.name;
          place = [representStartLot?.place?._id];
        } else {
          placeName = `${representStartLot?.place?.name}-${
            representEndLot?.place?.name || representEndLot?.warehouse?.name
          }`;
          place = [representStartLot?.place?._id, representEndLot?.place?._id];
        }

        const payload = {
          ref: `${count}-${pairIndex}`,
          startLot: representStartLot,
          endLot: representEndLot,
          employee: representEmployee,
          employeeId: representEmployee?._id,
          employeeName: `${representEmployee?.firstname} ${representEmployee?.lastname}`,
          placeName,
          place,
          duration: timeUsage,
          unitCost,
          cost: parseFloat(unitCost) * parseFloat(timeUsage),
          totalCost: parseFloat(unitCost) * parseFloat(timeUsage),
          representStartLotMaterialName,
          representEndLotMaterialName,
          inventory_workflow: workType,
        };

        employeeUsageList.push(payload);

        // Handle the Another Employee
        if (
          !_.isEmpty(representEndLot?.another_employees) &&
          includeAnotherEmployee
        ) {
          let anotherEmpIndex = 0;
          for await (const eachAnotherEmployee of representEndLot?.another_employees ||
            []) {
            let anotherUnitCost;
            if (isFrontend) {
              anotherUnitCost = await getEmployeePayrollUnitCostOnTime({
                representEmployee: eachAnotherEmployee?.employee,
                timePair: eachPair,
                information,
              });
            } else {
              anotherUnitCost = 0; // FIXME:Implement Unit Cost
            }
            const amountOfEmployee = eachAnotherEmployee?.amount;
            for (let i = 0; i < amountOfEmployee; i += 1) {
              const anotherPayload = {
                ref: `${count}-${pairIndex}-${anotherEmpIndex}-${i}`,
                employeeId: eachAnotherEmployee?.employee?._id,
                employeeName: `${eachAnotherEmployee?.employee?.firstname} ${eachAnotherEmployee?.employee?.lastname}`,
                employee: eachAnotherEmployee?.employee,
                placeName,
                place,
                duration: timeUsage,
                unitCost: anotherUnitCost,
                cost: parseFloat(anotherUnitCost) * parseFloat(timeUsage),
                totalCost: parseFloat(anotherUnitCost) * parseFloat(timeUsage),
                startLot: representStartLot,
                endLot: representEndLot,
                representStartLotMaterialName,
                representEndLotMaterialName,
                inventory_workflow: workType,
              };

              employeeUsageList.push(anotherPayload);
            }
            anotherEmpIndex += 1;
          }
        }
      }
      pairIndex += 1;
    }

    count += 1;
  }

  const filterPlaceEmployeeUsage = _.filter(employeeUsageList, (each) =>
    _.includes(each?.place, selectedPlace?._id),
  );

  return filterPlaceEmployeeUsage;
};

export default findLotWithCostRealTDABC;
