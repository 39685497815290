import _ from 'lodash';
import filterOnlyChildMaterial from '../../../cost-estimation/filterOnlyChildMaterial';

const calTDABCArtifact = async ({
  previousLotList,
  currentLot,
  warehouseProfile,
  api,
  isFrontend,
  information,
}) => {
  const place = warehouseProfile?.place;
  const allowMaterial = _.filter(
    previousLotList,
    (each) => each?.place?._id === place?._id,
  );
  const allowChildMaterial = filterOnlyChildMaterial({
    allowCategory: place?.allow_categories,
    currentLotMaterial: currentLot?.material,
    filterLotOnPlace: allowMaterial,
  });
  const hourPerDay = information?.setting?.pams?.daily_payroll?.working_hour;
  const minutePerDay = hourPerDay * 60;

  const calculateTotalCostPerMaterial = (lot) => {
    const costPerMinute = (lot?.place?.average_labor_cost || 0) / minutePerDay;
    const minuteUse =
      (lot?.material?.average_unit_time || 0) * (lot?.quantity || 0);

    const costPerOneMan = costPerMinute * minuteUse;
    const costPerManyPeople =
      costPerOneMan * (lot?.material?.average_labor_amount || 1);
    return costPerManyPeople;
  };

  const newCostList = _.map(allowChildMaterial, (row) => ({
    ...row,
    average_per_person: (row?.place?.average_labor_cost || 0) / minutePerDay,
    cost_per_person: calculateTotalCostPerMaterial(row),
  }));

  const totalCost = _.sumBy(allowChildMaterial, (o) =>
    calculateTotalCostPerMaterial(o),
  );

  return {
    cost: totalCost,
    costList: newCostList,
  };
};

export default calTDABCArtifact;
