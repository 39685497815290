import _ from 'lodash';
import findMaterialOriginalCost from '../../../cost-estimation/findMaterialOriginalCost';

const calculateCostFromMaterialAmount = async ({
  previousLotList,
  currentLot,
  warehouseProfile,
}) => {
  let cost = 0;

  const place = warehouseProfile?.place;
  const materialOnPlace = _.filter(
    previousLotList,
    (each) => each?.place?._id === place?._id,
  );
  const allowCategory = place?.allow_categories;

  // หาวัตถุดิบที่อนุญาคิให้อยู่ในคลังนี้
  const filterAllowanceMaterial = _.filter(materialOnPlace, (each) =>
    _.includes(allowCategory, each?.material?.material_type),
  );

  const originalCostList = findMaterialOriginalCost({
    allowanceMaterial: filterAllowanceMaterial,
    allLotList: previousLotList,
    materialOnPlace,
    useCostFromPrevious:
      warehouseProfile?.material?.use_cost_from_pervious_material,
  });

  // console.log('Original Cost List', originalCostList);

  const findAverageCost = (eachMaterialOriginalCost) => {
    const amountOfList = _.size(eachMaterialOriginalCost);
    const totalCost = _.sumBy(
      eachMaterialOriginalCost,
      (each) => each?.unit_cost || 0,
    );
    return totalCost / amountOfList;
  };

  const totalFromNoUsePreviousCost = _.sum(
    _.map(
      originalCostList,
      (each) => each?.unit_cost || 0 * (each?.quantity || 0),
    ),
  );

  const totalFromUsePreviousCost = _.sum(
    _.map(
      originalCostList,
      (each) => findAverageCost(each?.original_costs) * (each?.quantity || 0),
    ),
  );

  if (warehouseProfile?.material?.use_cost_from_pervious_material) {
    cost = totalFromUsePreviousCost;
  } else {
    cost = totalFromNoUsePreviousCost;
  }

  return { cost, costList: originalCostList };
};

export default calculateCostFromMaterialAmount;
