/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { MATERIAL_COST_ESTIMATION_METHOD } from '@iarcpsu/emanufac-constant';
import {
  currencyFormatter,
  filterAllowanceMaterial,
  findMaterialOriginalCost,
} from '@iarcpsu/emanufac-utils/functions';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { api } from '@/configs';

import MultiUnitProdOrMatBox from '../../Rendering/MultiUnitProdOrMatBox';

function MaterialAmountAndUnit({
  useCostFromPreviousMaterial,
  previousLotList,
  place,
  peerPlace,
}) {
  const [accrordingLot, setAccrodingLot] = useState([]);
  // หาล็อตวัตถุดิบในคลังนี้
  const materialOnPlace = _.filter(
    previousLotList,
    (each) => each?.place?._id === place?._id,
  );
  const allowCategory = place?.allow_categories;

  // หาวัตถุดิบในคลังนี้ **ที่ได้รับอนุญาติให็อยู่**
  const allowanceMaterial = _.filter(materialOnPlace, (each) =>
    _.includes(allowCategory, each?.material?.material_type),
  );

  // กรองวัตถุดิบ ที่เป็น Parent ของวัตถุดิบชิ้นนี้
  // ที่อยู่ในคลังที่เราตั้งค่าไว้ใช้คำนวณ (Peer Place)
  const materialOnCaluculatePlace = _.filter(
    previousLotList,
    (each) => each?.place?._id === peerPlace?._id,
  );

  // หาวัตถุดิบในคลังที่ใชำคำนวญ เฉพาะแบบที่อนุญาติเท่านั้น
  const filterAllowanceOnCalculatePlace = _.filter(
    materialOnCaluculatePlace,
    (each) =>
      _.includes(peerPlace?.allow_categories, each?.material?.material_type),
  );

  // หาล็อตแรกสุด (Original) ของล็อตที่อยู่ในคลัง ที่ถูกตั้งค่าให้ใช้คิดคำนวณ
  const originalCostList = findMaterialOriginalCost({
    allowanceMaterial: filterAllowanceOnCalculatePlace,
    allLotList: previousLotList,
    materialOnPlace: materialOnCaluculatePlace,
    useCostFromPrevious: useCostFromPreviousMaterial,
  });

  // จากล็อตแรกสุด กลับมาหาลํ็อตที่สืบทอดออกมาจากล๋อตแรกสุดนี้
  // ที่อยู่ในคลังที่เราตั้งค่าไว้ใช้คำนวณ (Peer Place)
  const findAccordingLot = async (uniqCostList) => {
    try {
      let allLotList = [];
      for await (const each of uniqCostList) {
        const { data } = await api.get(
          `${process.env.REACT_APP_API_URL}/material-stock-lot?ancestor=${each?.material_stock_lot}`,
        );
        const lotList = data.rows || [];
        allLotList = [...allLotList, ...lotList];
      }
      setAccrodingLot(allLotList);
    } catch (error) {
      console.error('Cannot Get According lot', error?.message);
    }
  };

  // console.log('Accroding Lot', accrordingLot);

  // ในต้นทาง หารายการที่ Unique ก่อน
  const originalCost = _.reduce(
    originalCostList,
    (result, value) => {
      _.map(value?.original_costs, (eachOriginalCost) => {
        result.push(eachOriginalCost);
      });
      return result;
    },
    [],
  );

  const uniqueOriginalCost = _.uniqBy(originalCost, 'material_stock_lot');

  useEffect(() => {
    findAccordingLot(uniqueOriginalCost);
    return () => {};
  }, [previousLotList]);

  // คำนวณต้นทุนจากล็อตต้นทางเริ่มแรก
  const totalCalculatedCost = _.sumBy(
    uniqueOriginalCost,
    (each) => each?.unit_cost * each?.lot_quantity || 0,
  );

  // ในล็อตทั้งหมดที่สืบทอด มาจากล็อตต้นทาง ของล็อตในคลังที่ใช้คำนวณ
  // นำมากรองเฉพาะในคลังนั้น
  const allowAccordingLot = filterAllowanceMaterial(accrordingLot, peerPlace);
  // console.log('Allow According Lot', allowAccordingLot);

  const sumQuantityOnCalculatePlace = _.sumBy(
    allowAccordingLot,
    (each) => each?.quantity || 0,
  );

  const calculateQuantityPercent = (row) =>
    (row?.quantity / sumQuantityOnCalculatePlace) * 100;

  const unitPercentCost = (1 / 100) * totalCalculatedCost;
  return (
    <div className="my-2">
      <div className="font-semibold my-2">
        ต้นทุนวัตถุดิบ:{' '}
        {
          MATERIAL_COST_ESTIMATION_METHOD.USE_AMOUNT_AND_PERCENT_USED_COST
            .description
        }
      </div>
      <div>
        ดึงข้อมูลจากคลัง :{' '}
        <span className="font-semibold">{peerPlace?.name || '-'}</span>
      </div>

      <div>โดยวัตถุดิบใน {peerPlace?.name || '-'}สืบทอดมาจาก</div>
      <div className="my-2">
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">เลขอ้างอิงล็อต</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ปริมาณที่รับมา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ต้นทุนต่อหน่วย</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ต้นทุนรวม</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(uniqueOriginalCost) ? (
                _.map(uniqueOriginalCost, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">{index + 1}</div>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/ims/material-stock-lot/${row?.lot?._id}`}
                        className="text-gray-700 underline hover:text-gray-600"
                      >
                        {row?.running_number}
                      </Link>
                    </TableCell>
                    <TableCell>{row?.material?.name}</TableCell>
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={row?.material}
                        quantity={row?.lot_quantity}
                      />
                    </TableCell>{' '}
                    <TableCell>
                      {currencyFormatter.format(row?.unit_cost)}
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(
                        (row?.unit_cost || 0) * (row?.lot_quantity || 0),
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
              {!_.isEmpty(uniqueOriginalCost) && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <div className="text-center">รวม</div>
                  </TableCell>
                  <TableCell colSpan={1}>
                    {currencyFormatter.format(totalCalculatedCost)} บาท
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        รวมมูลค่าใน {peerPlace?.name || '-'} มีค่า{' '}
        <b>{currencyFormatter.format(totalCalculatedCost)}</b> บาท <br />
        โดยมีวัตถุดิบจริงๆในคลังนี้{' '}
        {currencyFormatter.format(sumQuantityOnCalculatePlace)} หน่วย
        จะถูกตีเป็น 100%
        <br />
        ดังนั้น 1% มีมูลค่าเท่ากับ{' '}
        {currencyFormatter.format((1 / 100) * sumQuantityOnCalculatePlace)}{' '}
        หน่วย หรือ {currencyFormatter.format(unitPercentCost)} บาท
      </div>
      <div>ดังนั้นวัตถุดิบที่ใช้ในการผลิตจะมีมูลค่าเป็น</div>
      <div className="my-2">
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">เลขอ้างอิงล็อต</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ปริมาณที่ได้</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ปริมาณ %</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold">ต้นทุน</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(allowanceMaterial) ? (
                _.map(allowanceMaterial, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">{index + 1}</div>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/ims/material-stock-lot/${row?.lot?._id}`}
                        className="text-gray-700 underline hover:text-gray-600"
                      >
                        {row?.running_number}
                      </Link>
                    </TableCell>
                    <TableCell>{row?.material?.name}</TableCell>
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={row?.material}
                        quantity={row?.quantity}
                      />
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(calculateQuantityPercent(row))}{' '}
                      %
                    </TableCell>{' '}
                    <TableCell>
                      {currencyFormatter.format(
                        calculateQuantityPercent(row) * unitPercentCost,
                      )}{' '}
                      บาท
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
              {!_.isEmpty(allowanceMaterial) && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <div className="text-center">รวม</div>
                  </TableCell>
                  <TableCell colSpan={1}>
                    {currencyFormatter.format(
                      _.sumBy(
                        allowanceMaterial,
                        (each) =>
                          calculateQuantityPercent(each) * unitPercentCost,
                      ),
                    )}{' '}
                    บาท
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default MaterialAmountAndUnit;

MaterialAmountAndUnit.propTypes = {
  useCostFromPreviousMaterial: PropTypes.bool,
  previousLotList: PropTypes.array,
  place: PropTypes.object,
  peerPlace: PropTypes.object,
};
