/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import findMaterialOriginalCost from '../../../cost-estimation/findMaterialOriginalCost';
import filterAllowanceMaterial from '../../../cost-estimation/filterAllowanceMaterial';

const calculateCostFromMaterialPercent = async ({
  previousLotList,
  currentLot,
  warehouseProfile,
  api,
  isFrontend,
  MaterialStockLot,
  ObjectId,
}) => {
  try {
    console.log('Calculate Cost From Material Percent');
    const place = warehouseProfile?.place;
    const peerPlace = warehouseProfile?.material?.calculate_percent_from_place;
    const useCostFromPreviousMaterial =
      warehouseProfile?.material?.use_cost_from_pervious_material;

    // หาล็อตวัตถุดิบในคลังนี้
    const materialOnPlace = _.filter(
      previousLotList,
      (each) => each?.place?._id?.toString() === place?._id?.toString(),
    );
    const allowCategory = _.map(place?.allow_categories, (each) =>
      each?.toString(),
    );

    // หาวัตถุดิบในคลังนี้ **ที่ได้รับอนุญาติให็อยู่**
    const allowanceMaterial = _.filter(materialOnPlace, (each) =>
      _.includes(allowCategory, each?.material?.material_type?.toString()),
    );

    // กรองวัตถุดิบ ที่เป็น Parent ของวัตถุดิบชิ้นนี้
    // ที่อยู่ในคลังที่เราตั้งค่าไว้ใช้คำนวณ (Peer Place)
    const materialOnCaluculatePlace = _.filter(
      previousLotList,
      (each) => each?.place?._id?.toString() === peerPlace?._id?.toString(),
    );

    const peerAllowCategories = _.map(peerPlace?.allow_categories, (each) =>
      each?.toString(),
    );
    // หาวัตถุดิบในคลังที่ใชำคำนวญ เฉพาะแบบที่อนุญาติเท่านั้น
    const filterAllowanceOnCalculatePlace = _.filter(
      materialOnCaluculatePlace,
      (each) =>
        _.includes(
          peerAllowCategories,
          each?.material?.material_type?.toString(),
        ),
    );

    // หาล็อตแรกสุด (Original) ของล็อตที่อยู่ในคลัง ที่ถูกตั้งค่าให้ใช้คิดคำนวณ
    const originalCostList = findMaterialOriginalCost({
      allowanceMaterial: filterAllowanceOnCalculatePlace,
      allLotList: previousLotList,
      materialOnPlace: materialOnCaluculatePlace,
      useCostFromPrevious: useCostFromPreviousMaterial,
    });

    // ในต้นทาง หารายการที่ Unique ก่อน
    const originalCost = _.reduce(
      originalCostList,
      (result, value) => {
        _.map(value?.original_costs, (eachOriginalCost) => {
          result.push(eachOriginalCost);
        });
        return result;
      },
      [],
    );

    const uniqueOriginalCost = _.uniqBy(originalCost, 'material_stock_lot');

    let accrordingLot = [];
    if (isFrontend) {
      for await (const each of uniqueOriginalCost) {
        const { data } = await api.get(
          `${process.env.REACT_APP_API_URL}/material-stock-lot?ancestor=${each?.material_stock_lot}`,
        );
        const lotList = data.rows || [];
        accrordingLot = [...accrordingLot, ...lotList];
      }
    } else {
      for await (const each of uniqueOriginalCost) {
        const pipeline = [
          {
            $match: {
              $expr: {
                $in: [
                  ObjectId(each?.material_stock_lot),
                  { $ifNull: ['$ancestor_lots', []] },
                ],
              },
            },
          },
        ];
        const data = await MaterialStockLot.aggregate(pipeline);

        const lotList = data;
        accrordingLot = [...accrordingLot, ...lotList];
      }
    }

    console.log('According Lots', accrordingLot);

    // คำนวณต้นทุนจากล็อตต้นทางเริ่มแรก
    const totalCalculatedCost = _.sumBy(
      uniqueOriginalCost,
      (each) => (each?.unit_cost || 0) * (each?.lot_quantity || 0) || 0,
    );

    // ในล็อตทั้งหมดที่สืบทอด มาจากล็อตต้นทาง ของล็อตในคลังที่ใช้คำนวณ
    // นำมากรองเฉพาะในคลังนั้น
    const allowAccordingLot = filterAllowanceMaterial(accrordingLot, peerPlace);
    // console.log('Allow According Lot', allowAccordingLot);

    const sumQuantityOnCalculatePlace = _.sumBy(
      allowAccordingLot,
      (each) => each?.quantity || 0,
    );

    const calculateQuantityPercent = (row) =>
      ((row?.quantity || 0) / sumQuantityOnCalculatePlace) * 100;

    const unitPercentCost = (1 / 100) * totalCalculatedCost;

    const costList = _.map(allowanceMaterial, (each) => ({
      ...each,
      material_percent: calculateQuantityPercent(each),
      cost: calculateQuantityPercent(each) * unitPercentCost,
    }));

    const totalCost = _.sumBy(
      allowanceMaterial,
      (each) => calculateQuantityPercent(each) * unitPercentCost,
    );

    return { cost: totalCost, costList };
  } catch (error) {
    console.error(
      'Get Accoring lot error or something from material percent calculation',
      error.message,
    );
    return { cost: 0, costList: [] };
  }
};

export default calculateCostFromMaterialPercent;
