import calculateArtifactTDABC from './calculateArtifactTDABC';
import calculateRealTDABC from './calculateRealTDABC';

const calculateCCTDABC = async ({
  warehouseProfile,
  previousLotList,
  currentLot,
  api,
  isFrontend,
  information,
  FactoryCapitalCost,
}) => {
  let cost = 0;
  let costList = [];
  let tempData = { cost: 0, costList: [] };
  if (warehouseProfile?.capital_cost?.use_artifact_time_in_tdabc) {
    tempData = await calculateArtifactTDABC({
      previousLotList,
      currentLot,
      warehouseProfile,
      api,
      isFrontend,
      information,
      FactoryCapitalCost,
    });
    cost = tempData.cost;
    costList = tempData.costList;
  } else {
    tempData = await calculateRealTDABC({
      previousLotList,
      currentLot,
      warehouseProfile,
      api,
      isFrontend,
      information,
    });
    cost = tempData.cost;
    costList = tempData.costList;
  }

  return { cost, costList };
};

export default calculateCCTDABC;
