import api from '../../../configs/api';
import {
  MATERIAL_STOCK_LOT_ALL,
  MATERIAL_STOCK_LOT_DECREASE,
  MATERIAL_STOCK_LOT_DEL,
  MATERIAL_STOCK_LOT_ERROR,
  MATERIAL_STOCK_LOT_GET,
  MATERIAL_STOCK_LOT_INCREASE,
  MATERIAL_STOCK_LOT_LOADING,
  MATERIAL_STOCK_LOT_POST,
  MATERIAL_STOCK_LOT_PUT,
} from '../types';

export const materialStockLotCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_STOCK_LOT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/material-stock-lot`,
      {
        ...payload,
      },
    );
    dispatch({ type: MATERIAL_STOCK_LOT_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_STOCK_LOT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialStockLotDecrease = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_STOCK_LOT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/material-stock-lot`,
      {
        ...payload,
      },
    );
    dispatch({ type: MATERIAL_STOCK_LOT_DECREASE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_STOCK_LOT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialStockLotIncrease = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_STOCK_LOT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/material-stock-lot`,
      {
        ...payload,
      },
    );
    dispatch({ type: MATERIAL_STOCK_LOT_INCREASE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_STOCK_LOT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialStockLotAll = ({
  materialName = '',
  size = '',
  page = 1,
  stockStatus = 'IN_STOCK',
  place = '',
  material = '',
  ids = '',
  placeRestricNotify = '',
  startDate = '',
  endDate = '',
  strangerOnly = '',
  purchaseStartDate = '',
  purchaseEndDate = '',
  fetchProcess = '',
  ancestor = '',
  workflow = '',
  correctPlaceOnly = '',
  selectedProcess = '',
}) => async (dispatch) => {
  try {
    const query = new URLSearchParams({
      startDate,
      endDate,
      place,
      status: stockStatus,
      page,
      size,
      placeRestricNotify,
      strangerOnly,
      purchaseStartDate,
      purchaseEndDate,
      fetchProcess,
      material,
      materialName,
      ids,
      ancestor,
      workflow,
      correctPlaceOnly,
      process: selectedProcess,
    });
    const { data, status } = await api.get(
      `${
        process.env.REACT_APP_API_URL
      }/material-stock-lot/?${query.toString()}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIAL_STOCK_LOT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_STOCK_LOT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialStockLotGroupAll = ({
  startDate = '',
  endDate = '',
  place = '',
  status = 'IN_STOCK',
  groupBy = '',
  page = 1,
  size = '',
  placeRestricNotify = '',
  strangerOnly = '',
  purchaseStartDate = '',
  purchaseEndDate = '',
  fetchProcess = '',
}) => async (dispatch) => {
  try {
    const query = new URLSearchParams({
      startDate,
      endDate,
      place,
      status,
      groupBy,
      page,
      size,
      placeRestricNotify,
      strangerOnly,
      purchaseStartDate,
      purchaseEndDate,
      fetchProcess,
    });
    const { data, status: ResStatus } = await api.get(
      `${
        process.env.REACT_APP_API_URL
      }/material-stock-lot/with-group?${query.toString()}`,
    );

    if (ResStatus === 200) {
      dispatch({ type: MATERIAL_STOCK_LOT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_STOCK_LOT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialStockLotGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/material-stock-lot/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MATERIAL_STOCK_LOT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_STOCK_LOT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialStockLotStateSet = (data) => async (dispatch) => {
  dispatch({ type: MATERIAL_STOCK_LOT_ALL, payload: data });
};

export const materialStockLotReset = () => async (dispatch) => {
  dispatch({ type: MATERIAL_STOCK_LOT_ALL, payload: {} });
};

export const materialStockLotPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_STOCK_LOT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/material-stock-lot/${id}`,
      payload,
    );
    dispatch({ type: MATERIAL_STOCK_LOT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_STOCK_LOT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const moveMaterialStockLot = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_STOCK_LOT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/material-stock-lot/move/${id}`,
      payload,
    );
    dispatch({ type: MATERIAL_STOCK_LOT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_STOCK_LOT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialStockLotTransform = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_STOCK_LOT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/material-stock-lot/transform`,
      payload,
    );
    dispatch({ type: MATERIAL_STOCK_LOT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_STOCK_LOT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialStockLotDelete = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_STOCK_LOT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/material-stock-lot/${id}`,
      payload,
    );
    dispatch({ type: MATERIAL_STOCK_LOT_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MATERIAL_STOCK_LOT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const materialStockLotError = () => async (dispatch) => {
  dispatch({ type: MATERIAL_STOCK_LOT_ERROR });
};
